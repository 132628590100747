import { useEffect, useState } from "react"

//validaciones
import { z } from 'zod';

// pop up api
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

// styles
import './Dashboard.scss'

// utils
import { v4 as uuid } from "uuid";
import obtenerProgreso from "../../Utils/obtenerProgreso";

// components
import Input from "../Input/Input";
import { ProgressBar } from 'primereact/progressbar';


//schemas
// Define el esquema de validación con Zod
const recruitSchema = z.object({
  nombre: z.string().min(1, "El nombre es requerido"),
  telefono: z.string().min(1, "El teléfono es requerido"),
  email: z.string().email("El email debe ser válido"),
  estado: z.string().min(1, "El estado es requerido"),
  usuario: z.string().min(1, "El usuario es requerido"),
  clave: z.string().min(1, "La clave es requerida"),
  readyToCreate: z.boolean()
});


export default function ({ asesorData, setAsesorData, reclutasDB }) {
  // DB connection
  const AppAPI = new SteinStore(process.env.REACT_APP_ST_API);
  const [LeccionesDB, setLeccionesDB] = useState(null)
  const [progresoReclutas, setProgresoReclutas] = useState([])
  const [newRecruit, setNewRecruit] = useState({
    nombre: "",
    telefono: "",
    email: "",
    estado: "",
    usuario: "",
    clave: "",
    readyToCreate: false,
  })



  const handleChangeRecruit = (key, value) => {
    setNewRecruit({ //HEY VERIFICA QUE ESTO FUNCIONE
      ...newRecruit,
      [key]: value
    })
  }


  //creamos en base de datos el nuevo recluta
  const handleCreate = () => {
    if (newRecruit.readyToCreate) {
      Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })

      const _new = {
        uuid: uuid(),
        nombre: newRecruit.nombre,
        telefono: newRecruit.telefono,
        email: newRecruit.email,
        estado: newRecruit.estado,
        usuario: newRecruit.usuario,
        clave: newRecruit.clave,
        progreso: 1,
      }

      AppAPI
        .append(
          "Reclutas",
          [_new],
          {
            authentication: {
              username: process.env.REACT_APP_ST_USERNAME,
              password: process.env.REACT_APP_ST_PASSWORD
            }
          }
        )
        .then(_ => {
          setNewRecruit({
            nombre: "",
            telefono: "",
            email: "",
            estado: "",
            usuario: "",
            clave: "",
            readyToCreate: false,
          })
          Swal.fire({
            title: 'Registro enviado con éxito',
            icon: 'success',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#0F70D4",
            timer: 1500
          })

        })
        .catch(e => {
          Swal.fire({ // Pop up de error
            title: 'Ha ocurrido un error',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCloseButton: false,
          })
        })
    }
  }





  //obtengo todas las lecciones
  useEffect(() => {
    //aqui consultaremos el numero total de lecciones para sacar los porcentajes con cada recluta

    AppAPI
      .read(
        "Lecciones",
        {
          authentication: {
            username: process.env.REACT_APP_ST_USERNAME,
            password: process.env.REACT_APP_ST_PASSWORD
          }
        }
      )
      .then(baseDeDatos => {
        setLeccionesDB(baseDeDatos)
        
      })
      .catch(e => {
        Swal.fire({ // Pop up de error
          title: 'Ha ocurrido un error',
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          showCloseButton: false,
        })
      })
  }, [])


  //preparo el array que voy a mapear para las graficas de progreso
  useEffect(() => {
    if (LeccionesDB) { //solamente si ya cargó todas las lecciones
      let _progreso = []

      reclutasDB.forEach(recluta => {
        _progreso.push({
          key: recluta.uuid,
          nombre: recluta.nombre,
          progreso: obtenerProgreso(LeccionesDB, recluta.progreso),
          telefono: recluta.telefono,
          email: recluta.email,
        })
      })

      setProgresoReclutas(_progreso)
    }
  }, [LeccionesDB])


  //validamos formularioo de nuevo recluta
  useEffect(() => {
    // Validar con Zod
    const validationResult = recruitSchema.safeParse(newRecruit);

    setNewRecruit((prev) => {
      const ready = validationResult.success;
      if (prev.readyToCreate !== ready) {
        return { ...prev, readyToCreate: ready };
      }
      return prev;
    });

  }, [newRecruit])





  return (<div className="Dashboard">
    <div className="DashboardWrapper">
      <section className="reclutas">
        <div className="reclutasWrap">
          <h1>Gestión de Asesores</h1>
          
          {
            progresoReclutas.map(recluta => //gu+ia de primme reacct
              <div
                key={recluta.key}
                className="ReclutaProgress"
              >
                <p><span>{recluta.nombre}</span> | {recluta.telefono} | {recluta.email}</p>
                <ProgressBar value={recluta.progreso} />
              </div>
            )
          }
        </div>
      </section>


      <section className={"nuevoRecluta"}>
        <h1>Nuevo Asesor</h1>
        
        <h3>Nombre Completo</h3>
        <div style={{ height: "10px" }} />
        <Input
          value={newRecruit.nombre}
          onChange={e => handleChangeRecruit("nombre", e.target.value)}
        />
        <div style={{ height: "20px" }} />



        <h3>Teléfono</h3>
        <div style={{ height: "10px" }} />
        <Input
          value={newRecruit.telefono}
          onChange={e => handleChangeRecruit("telefono", e.target.value)}
        />
        <div style={{ height: "20px" }} />



        <h3>Email</h3>
        <div style={{ height: "10px" }} />
        <Input
          type={"email"}
          value={newRecruit.email}
          onChange={e => handleChangeRecruit("email", e.target.value)}
        />
        <div style={{ height: "20px" }} />



        <h3>Estado</h3>
        <div style={{ height: "10px" }} />
        <Input
          value={newRecruit.estado}
          onChange={e => handleChangeRecruit("estado", e.target.value)}
        />
        <div style={{ height: "20px" }} />



        <h3>Usuario</h3>
        <div style={{ height: "10px" }} />
        <Input
          value={newRecruit.usuario}
          onChange={e => handleChangeRecruit("usuario", e.target.value)}
        />
        <div style={{ height: "20px" }} />



        <h3>RFC</h3>
        <div style={{ height: "10px" }} />
        <Input
          value={newRecruit.clave}
          onChange={e => handleChangeRecruit("clave", e.target.value)}
        />
        <div style={{ height: "20px" }} />


        <button disabled={!newRecruit?.readyToCreate} onClick={handleCreate}>Crear Recluta</button>
      </section>
    </div>
  </div>
  )
}