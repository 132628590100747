import { useEffect, useState } from "react"


// pop up api
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

// styles
import './SchoolLayout.scss'

// components
import Player from "../Player/Player"
import Quiz from "../Quiz/Quiz"
import Lessons from "../Lessons/Lessons"
import Certificate from "../Certificate/Certificate";


export default function ({ asesorData, setAsesorData }) {
  // DB connection
  const AppAPI = new SteinStore(process.env.REACT_APP_ST_API);
  const [LessonsDB, setLessonsDB] = useState(null)
  const [strictFirstRender, setStrictFirstRender] = useState(1)

  // por defecto la ultima leccion en la que se quedo el asesor
  // pero se va actualizando conforme hagamos click en el las lecciones desbloqueadas
  const [lesson, setLesson] = useState(null)
  const [permissions, setPermissions] = useState({
    quiz: false,
    nextLesson: false,
    evaluate: false,
    tryAgain: false,
    resetVideo: false,
    completedCourse: false
  })
  const permissionsParams = {
    permissions,
    setPermissions
  }
  const lessonsParams = {
    lesson, 
    setLesson, 
    LessonsDB, 
    setLessonsDB
  }







  const handleEnd = () => {
    setPermissions({ ...permissions, quiz: Boolean(lesson?.quizRequired) })
  }








  useEffect(() => {
    setStrictFirstRender(2) 
    if(strictFirstRender == 2) {
      AppAPI // Obtendremos todas las lecciones para cuando vaya avanzando poder accederlas y todo eso
        .read("Lecciones", {authentication: {
          username: process.env.REACT_APP_ST_USERNAME, 
          password: process.env.REACT_APP_ST_PASSWORD
        }})
        .then(baseDeDatos => {
          setLessonsDB(baseDeDatos)
        })
        .catch(e => {
          Swal.fire({
            title: 'No pudimos cargar tus lecciones',
            icon: 'error',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#ff4545",
            timer: 2500
          })
        })
    }
  }, [strictFirstRender])

  useEffect(() => { // SELECCIONA LECCION EN LA QUE TE QUEDASTE
    if(LessonsDB) {
      const lastLessonIdx = LessonsDB.findIndex(lesson => lesson.uuid == asesorData.asesorProgreso)
      const lastLesson = LessonsDB[lastLessonIdx]

      if(lastLesson) { // en caso de que ya hayas terminado el cuestionario aquí no encuentra la lastLesson
        setLesson({
          uuid: lastLesson.uuid,
          title: lastLesson.titulo,
          quizRequired: parseInt(lastLesson.requiere_cuestionario),
          quiz: lastLesson.preguntas_respuestas && JSON.parse(lastLesson.preguntas_respuestas),
          video: lastLesson.video,
          archivos: lastLesson?.archivos ? JSON.parse(lastLesson?.archivos) : {}
        })
      } else {
        setPermissions({
          ...permissions,
          completedCourse: true
        })
      }
    }
  }, [LessonsDB])



  return (
    permissions?.completedCourse ?
      <Certificate asesorData={asesorData} setAsesorData={setAsesorData} {...permissionsParams} />

      :
      <section className={`SchoolLayout s-wrapper`}>
        <div className="s-layout">
          <Player {...permissionsParams} lesson={lesson} handleEnd={handleEnd} /> {/* representan info de la leccion actual */}
          
          <Quiz {...permissionsParams} asesorData={asesorData} lesson={lesson} /> {/* representan info de la leccion actual */}

          <Lessons {...permissionsParams} {...lessonsParams}  asesorData={asesorData} setAsesorData={setAsesorData} />
        </div>
      </section>
  )
}