import React, { useEffect, useState } from "react";


// prime react
import { RadioButton } from 'primereact/radiobutton';

//styles
import "./Question.scss"

// utils
const _createOptionsObject = (options) => {
  const _optionsMap = []

  options.forEach(option => { // creamos este formato para prime react
    let _option = {
      name: option,
      key: option
    }

    _optionsMap.push(_option)
  })

  return _optionsMap
}

export default function ({ quiz, question, selections, setSelections, permissions, lesson }) {
  const answer = quiz[question][quiz[question].length - 1] //the last item in the array is the answer of this question

  const options = _createOptionsObject(quiz[question].slice(0, -1))
  const [selected, setSelected] = useState();

  const onSelectedChange = (newValue) => {
    setSelected(newValue)
    const nameNewValue = newValue.name

    const _selections = [...selections]

    // verificamos si no existe ya esta pregunta en el objeto master, para crear una nueva o modificar solo el selected
    const editSelection = _selections.findIndex(selection => selection.question == question)

    if (editSelection !== -1) {

      //edit la seleccion ya existente
      _selections[editSelection].selection = nameNewValue
      _selections[editSelection].correct = answer == nameNewValue
      setSelections(_selections)

    } else {

      //crete selection
      _selections.push({ question: question, answer: answer, selection: nameNewValue, correct: answer == nameNewValue })
      setSelections(_selections)

    }

  }
  
  return (
    <div className="Question">


      <h3>{question}</h3>

      <div className="card">
        <div className="optionsContainer">
          {options.map((option) => {
            return (
              <div key={option.key} className="optionWrap">

                <RadioButton
                  inputId={option.key}
                  name="option"
                  value={option}
                  onChange={(e) => onSelectedChange(e.value)}
                  checked={selected?.key === option.key}
                  disabled={permissions.tryAgain || permissions.nextLesson}
                />

                <label
                  htmlFor={option.key}
                  className={"ml-2 "+
                    ((permissions.tryAgain || permissions.nextLesson) && selected?.key === option.key ? 
                      selected?.key === answer ?
                      "green"
                      
                      :
                      "red"
                      : "")
                  }
                >
                  {option.name}
                </label>

              </div>
            );
          })}
        </div>
      </div>


    </div>
  )
}