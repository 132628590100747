import { useEffect } from "react"

// styles
import "../../Styles/AppLayout.scss"
 
  
export default function({title, children, className}) {
  useEffect(() => {

  }, [])


  return (<section className={`app-page-wrap-container ${className ?? ""}`}>
    <div className="app-page-container">
      <div style={{height: "15px"}} />

      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <h1 className="h1" style={{textAlign: "center"}}>
          {title}
        </h1>
      </div>

      <div style={{height: "25px"}} />

      {children}
    </div>
  </section>)
}