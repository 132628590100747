import React, { useEffect, useRef } from "react"



// styles
import './FilesContainer.scss'



export default function ({
  files
}) {


  const helpScrollToFiles = () => {
    // Obtener el elemento div por su ID
    const miDiv = document.getElementById('filesContainerTitle');

    // Hacer que la página se desplace automáticamente hacia el div
    miDiv.scrollIntoView({ behavior: 'smooth' });
  }

  const fileItems = Object.entries(files).map(([key, value]) => (
    <a className="fileContainer" key={key} href={value} download target="_blank">
      <i className="pi pi-cloud-download"/>
      <span>{key}</span>
    </a>
  ));



  return (<div className="filesContainer">
    {
      Object.keys(files).length === 0 ?
        <h3 className="h3">Esta lección no tiene archivos</h3>

        :
        <React.Fragment>
          <h3 className="h3" id="filesContainerTitle" onClick={helpScrollToFiles}>Ver archivos de la lección ↓</h3>

          <div className="files" id="">
            {fileItems}
          </div>
        </React.Fragment>
    }
  </div>)
}