import { useEffect, useState, useRef } from "react";

import './Certificate.scss';

//img
import gnp from "../../Images/gnp.png"
import footerDecor from "../../Images/footer-decoration.svg"
import logoMid from "../../Images/logoMid.png"


export default function Certificate({ asesorData, setAsesorData, permissions, setPermissions }) {
  const [currentDate, setCurrentDate] = useState('');
  const [strictFirstRender, setStrictFirstRender] = useState(1);
  const certificateRef = useRef(null);




  const handleRepasar = () => {
    setAsesorData({
      ...asesorData,
      repasar: true
    })

    
    setPermissions({
      ...permissions,
      completedCourse: false
    })
  }
  
  
  

  useEffect(() => {
    setStrictFirstRender(2)
    
    if(strictFirstRender) {
      const date = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('es-ES', options);
      setCurrentDate(formattedDate);
    }
  }, []);







  return (
    <section className="Certificate" ref={certificateRef}>
      <div className="certificate-layout">
        <div className="header-decoration">
          <img src={footerDecor} alt="Footer Decoration" />
        </div>


        <div className="header">
          <img src={gnp} alt="GNP Logo" className="gnp-logo" />
          <img src={logoMid} alt="MID Logo" className="mid-logo" />
        </div>

        <div className="content">
          <p>OTORGAN LA PRESENTE</p>
          <h2>CONSTANCIA</h2>
          <p>A</p>
          <h1>{asesorData.asesorNombre}</h1>
          <p>Al concluir satisfactoriamente el curso de:</p>
          <h3>Inducción a MID Consultoría Financiera GNP</h3>
          <p>Ilse Daniela Macías Chávez</p>
          <p>Gerencia de Desarrollo de Asesores</p>
          <p>{currentDate}</p>
        </div>



        <div className="footer-decoration">
          <img src={footerDecor} alt="Footer Decoration" />
        </div>
      </div>

      <div className="repasar" onClick={handleRepasar}>Repasar mis lecciones</div>
    </section>
  );
}
