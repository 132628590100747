import React, { useEffect, useRef } from "react"



// styles
import './Player.scss'
import FilesContainer from "../FilesContainer/FilesContainer";


export default function ({ permissions, setPermissions, lesson, handleEnd }) {

  const videoRef = useRef(null);




  const restartVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  


  useEffect(() => {
    if (permissions.resetVideo) {
      restartVideo()
      setPermissions({
        quiz: false,
        nextLesson: false,
        evaluate: false,
        tryAgain: false,
        resetVideo: false,
      })
    }
    // obtengo los datos de la leccion seleccionada y muestro su titulo y el link del video
  }, [permissions])


  return (<section className={`s-player`}>
    {
      lesson &&
      <React.Fragment>
        <div className="title">
          <h1>
            {lesson.uuid}.- {lesson.title}
          </h1>
        </div>
        <div className="payerSubContainer">
          <div className="video">
            <video
              ref={videoRef}
              src={lesson.video}
              controls={!permissions.quiz}
              onEnded={handleEnd}
            >
            </video>
          </div>






          <FilesContainer files={
            lesson?.archivos ? lesson?.archivos : {}
          } />


        </div>
      </React.Fragment>
    }
  </section>)
}