import React, { useEffect, useState } from "react"

// styles
import './Quiz.scss'

// component
import Question from "../Question/Question"
import evaluateQuiz from "../../Utils/evaluateQuiz"


export default function ({ lesson, permissions, setPermissions, asesorData }) {
  const [selections, setSelections] = useState([])


  const handleEvaluation = () => {
    if (evaluateQuiz(selections)) {

      setPermissions({
        ...permissions,
        nextLesson: true
      })

    } else {

      setPermissions({
        ...permissions,
        evaluate: false,
        tryAgain: true,
      })
    }
  }

  const handleTryAgain = () => {
    setPermissions({
      quiz: false,
      evaluate: false,
      tryAgain: false,
      nextLesson: false,
      resetVideo: true,
    })
  }

  const handleNoRequired = () => {
    setPermissions({
      ...permissions,
      nextLesson: true,
    })
  }


  useEffect(() => {
    if (!lesson?.quizRequired) {

      // si no es requerida, pasale bro
      setPermissions({ ...permissions, evaluate: true })

    } else {

      // Cuando hayamos respondido todas las preguntas hacemos posible el evaluar las opciones
      const questionsN = Object.keys(lesson?.quiz).length
      const answersN = selections.length

      setPermissions({ ...permissions, evaluate: questionsN == answersN })

    }
  }, [lesson, selections])

  useEffect(() => {
    //cada que cambie la lección hay que resetear las selecciones
    setSelections([])
  }, [lesson])



  return (<section className={`s-quiz`}>

    {
      lesson && lesson.quizRequired ? //MOSTRAMOS TODO SOLO SI LA LECCIÓN REQUIERE CUESTIONARIO
        <>{
          permissions.quiz ?
            <React.Fragment>
              <h2>Evaluación de Módulo</h2>

              <div className="separador" />

              {
                Object.keys(lesson?.quiz).map((question, i) =>
                  <Question
                    key={i}
                    quiz={lesson?.quiz}
                    question={question}

                    selections={selections}
                    setSelections={setSelections}

                    lesson={lesson}
                    permissions={permissions}
                  />
                )
              }


              {permissions.evaluate && <button onClick={handleEvaluation}>Calificar</button>}
              {permissions.tryAgain && <button onClick={handleTryAgain}>Intentar de nuevo</button>}
            </React.Fragment>

            : <h2>Concluye la lección para poder realizar la evaluación</h2>
        }</>


        :
        <>{
          lesson?.uuid == asesorData.asesorProgreso ?
              <button onClick={handleNoRequired}>Siguiente lección</button>


            

            : <h2>¡Lección completada!</h2>
        }</>
    }


  </section>)
}