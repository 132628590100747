import { useEffect, useState } from "react"


// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

// styles
import './LessonCard.scss'


export default function ({ asesorData, permissions, setPermissions, lesson, setLesson }) {
  const disabled = (permissions.quiz) || (parseInt(lesson.uuid) > asesorData.asesorProgreso)
  // const [currentLesson, setCurrentLesson] = useState(parseInt(asesorData.asesorProgreso))


  //hagamos que cuando el uuid no esté dentro del progreso de mi pana, la lección esté desabilitada
  const handleClick = () => { // PARA CUANDO SELECCIONAS UNA LECCIÓN YA REALIZADA
    
    if(!disabled) {
      setLesson({
        uuid: lesson.uuid,
        title: lesson.titulo,
        quizRequired: 
          parseInt(lesson.requiere_cuestionario) === 0 ? //requiere cuestionario?
            false
            :
            !(parseInt(lesson.uuid) < asesorData.asesorProgreso) //si el asesor ya tiene esta leccion, el quiz no es necesario
          ,
        quiz: lesson.preguntas_respuestas && JSON.parse(lesson.preguntas_respuestas),
        video: lesson.video,
        archivos: lesson?.archivos ? JSON.parse(lesson?.archivos) : {}
      })

      setPermissions({
        quiz: false,
        nextLesson: false,
        evaluate: false,
        tryAgain: false,
        resetVideo: true,
      })
    }
  }

  


  return (
    <div 
      className={"LessonCard "+(disabled && "disabled")}
      onClick={handleClick}
    >
      {lesson.uuid}.- {lesson.titulo}
    </div>
  )
}