export default function obtenerProgreso(lecciones, leccionActual) {
    const _actual = parseInt(leccionActual)

    
    if(_actual == 1) {
        return 0
    }
    
    // Validación de la lección actual
    if (_actual < 1) {
        throw new Error("La lección actual debe estar dentro del rango de lecciones disponibles.");
    }

    // Calculo del progreso en porcentaje
    const totalLecciones = lecciones.length;
    const progreso = ((_actual - 1) / totalLecciones) * 100;

    return progreso.toFixed(0);
}