import { useEffect, useState } from "react";

// Components
import SchoolLayout from "../../Components/SchoolLayout/SchoolLayout";
import Dashboard from "../../Components/Dashboard/Dashboard";

// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./Main.scss"



export default function({
  title,
  cua,
  basesDeDatos,
  asesorData,
  setAsesorData,
  reclutasDB
}) {


  return (
    asesorData.asesorIsAdmin ?
    // portal para admin
    <Dashboard reclutasDB={reclutasDB} />

    // portal para estudiante
    : <SchoolLayout asesorData={asesorData} setAsesorData={setAsesorData} /> 
  );
}