import { useEffect, useState } from "react"


// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

// styles
import './Lessons.scss'
import LessonCard from "../LessonCard/LessonCard";

//img
import isotipo from "../../Images/IsotipoBlanco.png"


export default function ({ asesorData, setAsesorData, permissions, setPermissions, lesson, setLesson, LessonsDB }) {
  // DB connection
  const AppAPI = new SteinStore(process.env.REACT_APP_ST_API);
  const [currentLesson, setCurrentLesson] = useState(parseInt(asesorData.asesorProgreso))

  // detecta cuando han conseguido pasar la lección actual
  // actualiza base de datos y 
  useEffect(() => {
    if (permissions.nextLesson) {
      // si ha pasado la lección actual exitosamente, lo actualizaremos en su servidor
      // actualizar la api con su progreso +1

      //hacemos el update
      const loadingSwal = Swal.fire({
        title: '!Felicidades!',
        text: 'Has avanzado en tu progreso',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })


      AppAPI
        .edit(
          "Reclutas",
          {
            search: { uuid: asesorData.asesorUuid },
            set: { progreso: currentLesson+1 },
            authentication: { username: process.env.REACT_APP_ST_USERNAME, password: process.env.REACT_APP_ST_PASSWORD }
          }
        )

        .then(data => {
          setCurrentLesson(currentLesson+1)
          setAsesorData({
            ...asesorData,
            asesorProgreso: currentLesson+1
          })
          loadingSwal.close()
        })
        
        .catch(data => {
          Swal.fire({ // Pop up de error
            title: 'Ha ocurrido un error',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCloseButton: false,
          })
        })
    }
  }, [permissions])

  

  useEffect(() => {
    if(LessonsDB) { 
      if(!asesorData?.repasar) {

        if(LessonsDB.length < currentLesson) { // TERMINASTE TODAS LAS LECCIONES
  
          setPermissions({
            ...permissions,
            quiz: false,
            nextLesson: false,
            evaluate: false,
            tryAgain: false,
            resetVideo: false,
            completedCourse: true,
          })
          
          
        } else {
          
          // PARA CUANDO TERMINAS UN CUESTIONARIO EXITOSAMENTE E INGRESAS POR PRIMERA VEZ
          const nextLessonIndex = LessonsDB.findIndex(lesson => lesson?.uuid == currentLesson+"")
          const nextLesson = LessonsDB[nextLessonIndex]
      
          setLesson({
            uuid: nextLesson.uuid,
            title: nextLesson.titulo,
            quizRequired: nextLesson.requiere_cuestionario,
            quiz: nextLesson.preguntas_respuestas && JSON.parse(nextLesson.preguntas_respuestas),
            video: nextLesson.video,
            archivos: nextLesson?.archivos ? JSON.parse(nextLesson?.archivos) : {}
          })
          setPermissions({
            quiz: false,
            nextLesson: false,
            evaluate: false,
            tryAgain: false,
            resetVideo: true,
          })
        }
      }
      
      
    }
    
  }, [currentLesson, asesorData])



  return (<section className={`s-lessons`}>


    <img src={isotipo} className="isotipo"/>


    {
      LessonsDB && LessonsDB.map(lesson =>
        <LessonCard 
          key={lesson.uuid}
          lesson={lesson}
          setLesson={setLesson}
          permissions={permissions}
          setPermissions={setPermissions}
          asesorData={asesorData}
        />        
      )
    }





  </section>)
}